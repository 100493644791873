var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('custom-vue-table',{ref:"table",staticClass:"mt-5",attrs:{"showSearchBox":false,"title":"Attachments","fields":_vm.fields,"url":_vm.listAttachment,"per-page":10,"extra-params":{entry_id: _vm.entry_id}},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('div',{staticClass:"btn-group ml-3"},[(_vm.status!=='Payment Completed')?_c('btn',{attrs:{"icon":"fa fa-plus","size":"md","text":"Add"},on:{"click":function($event){return _vm.$refs.addModal.show()}}}):_vm._e()],1)]},proxy:true},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"btn-group-tight"},[(_vm.status!=='Payment Completed')?_c('btn',{staticClass:"btn-basic-b",attrs:{"size":"xs","icon":"fa fa-trash"},on:{"click":function($event){return _vm.setDelete(rowData)}}}):_vm._e(),(_vm.status!=='Payment Completed')?_c('btn',{staticClass:"btn-basic-b",attrs:{"size":"xs","icon":"fa fa-pencil"},on:{"click":function($event){return _vm.setEdit(rowData)}}}):_vm._e(),_c('btn',{staticClass:"btn-basic-b",attrs:{"size":"xs","icon":"fa fa-eye"},on:{"click":function($event){return _vm.viewFile(rowData)}}})],1)]}}])}),_c('modal',{ref:"editModal",attrs:{"no-close-on-backdrop":"","title":"Update Attachments","width":"30r"}},[_c('b-form',{attrs:{"save-url":_vm.addUrl,"initial-data":_vm.editingItem,"save-params":{entry_id: _vm.entry_id, purchase_entry : _vm.entry_id}},on:{"success":_vm.formSuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('validated-file-input',{attrs:{"label":"Attachment","disabled":loading},model:{value:(model.attachments),callback:function ($$v) {_vm.$set(model, "attachments", $$v)},expression:"model.attachments"}})],1)]),_c('btn',{attrs:{"size":"xs","text":"Update","loading":loading,"loading-text":"Updating..."}})]}}])})],1),_c('modal',{ref:"addModal",attrs:{"no-close-on-backdrop":"","title":"Upload Attachments","width":"30r"}},[_c('b-form',{attrs:{"save-url":_vm.addUrl,"save-params":{entry_id: _vm.entry_id, purchase_entry : _vm.entry_id}},on:{"success":_vm.formSuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col"},[_c('validated-file-input',{attrs:{"label":"Attachment","disabled":loading},model:{value:(model.attachments),callback:function ($$v) {_vm.$set(model, "attachments", $$v)},expression:"model.attachments"}})],1)]),_c('btn',{attrs:{"size":"xs","text":"Upload","loading":loading,"loading-text":"Uploading..."}})]}}])})],1),_c('delete-modal',{ref:"deleteModal",attrs:{"url":_vm.deleteUrl,"params":_vm.deletingItem},on:{"response":_vm.deleteComplete},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('delete-animation'),_vm._v(" Please wait while we delete the file. ")]},proxy:true}])},[_c('p',[_vm._v("You are about to delete the file. Are you sure ?")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }