<template>
    <div class="card mt-5 bs-4 p-0 pb-3">
        <h5 class="bb-1 p-3">Payment Details</h5>
        <div class="row pl-3 pt-3 pr-5">
            <div class="col-1 label">Amount</div>
            <div class="col-2 ">
                <validated-input placeholder="Amount" name="Amount" v-model="model.amount"/>
            </div>
            <div class="col-1"/>

            <div class="col-1 label">Method</div>
            <div class="col-2 ">
                <validated-vue-select name="Method" :options="paymentOptions"
                                      placeholder="Method"
                                      v-model="model.payment_mode"/>
            </div>
            <div class="col-1"/>

            <div class="col-1 label">Payment Status</div>
            <div class="col-2 ">
                <validated-vue-select name="Payment Status" :options="statusOptions"
                                      placeholder="Payment Status"
                                      v-model="model.payment_status"/>
            </div>
            <div class="col-1"/>
        </div>
        <div class="row pl-3 pt-3 pr-5">

            <div class="col-1 label">Reference</div>
            <div class="col-2 ">
                <validated-input placeholder="Reference" name="Reference" v-model="model.reference"/>
            </div>
            <div class="col-1"/>

            <div v-if="payment.payment_date" class="col-1 label">Date</div>
            <div v-if="payment.payment_date" class="col-2 ">
                <validated-input disabled placeholder="Date" name="Date" v-model="payment.payment_date"/>
            </div>
            <div v-if="payment.payment_date" class="col-1"/>

            <div v-if="status==='PA Approved'" class="col-1 label">Remarks</div>
            <div v-if="status==='PA Approved'" class="col-2 ">
                <validated-input placeholder="Remarks" name="Remarks" v-model="model.remarks"/>
            </div>
            <div v-if="status==='PA Approved'" class="col-1"/>
        </div>
        <div v-if="status==='PA Approved'" class="row pl-3 pt-3 pr-5">
            <div class="col-1 label">Status</div>
            <div class="col-2 ">
                <validated-vue-select name="Status" :options="entryStatusOptions"
                                      placeholder="Status"
                                      v-model="model.status"/>
            </div>
            <div class="col-1"/>
        </div>
    </div>
</template>

<script>
import urls from '../../../../../data/urls';
import axios from 'secure-axios';

export default {
    name  : 'PaymentDetailsPage',
    props : {
        entry_id : { type : String },
        payment  : { type : Object },
        status   : { type : String }
    },
    data () {
        return {
            data        : '',
            DataLoading : false,
            model       : {
                entry_id       : '',
                remarks        : '',
                status         : '',
                amount         : '',
                date           : '',
                payment_mode   : '',
                payment_status : '',
                reference      : ''
            },
            statusOptions : [
                { value : 'Pending', label : 'Pending' },
                { value : 'Partially Completed', label : 'Partially Completed' },
                { value : 'Completed', label : 'Completed' }
            ],
            entryStatusOptions : [
                { value : 'Approved', label : 'Approve' },
                { value : 'Rejected', label : 'Reject' }
            ],
            paymentOptions : [
                { value : 'Cash', label : 'Cash' },
                { value : 'Fund Transfer', label : 'Fund Transfer' },
                { value : 'UPI Payment', label : 'UPI Payment' },
                { value : 'Demand Draft', label : 'Demand Draft' },
                { value : 'NEFT', label : 'NEFT' },
                { value : 'RTGS', label : 'RTGS' },
                { value : 'Wallet Transfer', label : 'Wallet Transfer' }
            ]
        };
    },
    mounted () {
        this.setData();
    },
    methods : {
        setData () {
            this.model.entry_id = this.entry_id;
            if (this.payment) {
                this.model.remarks = this.payment.remarks;
                this.model.status = this.payment.status;
                this.model.amount = this.payment.amount;
                this.model.date = this.payment.payment_date;
                this.model.payment_mode = this.payment.payment_mode;
                this.model.payment_status = this.payment.payment_status;
                this.model.reference = this.payment.reference;
            }
        },
        async submitClicked () {
            this.model.entry_id = this.entry_id;
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.financeAdmin.purchaseEntry.submit, that.model);
            const json = response.data;
            if (json.error === false) {
                that.$notify('Submitted Successfully', 'Success', {
                    type : 'success'
                });
                that.$router.push('/finance/entry/list/');
                that.loading = false;
            } else {
                that.$notify('Unable to submit the entry.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.loading = false;
            }
        }
    }
};
</script>

<style scoped>
.label {
    font-weight: 500;
}
</style>
