<template>
    <loading-animation v-if="DataLoading"></loading-animation>
    <div v-else class="ml-3 mr-3">
        <div class="fl-te-c mb-3 m-3">
            <div>
                <h4>Purchase Details</h4>
            </div>
            <div class="btn-group">
                <btn @click="$router.push('/finance/entry/' + id + '/history/')" size="sm" icon="fa fa-history"
                     text="History"/>
                <btn @click="$router.push('/finance/entry/list/')" size="sm" text="Back"/>
                <btn v-if="entry.status!=='Payment Completed'" size="sm" text="Submit"
                     @click="$refs.payment.submitClicked()"/>
            </div>
        </div>
        <purchase-entry-details-table v-if="entry" :entry="entry" class="m-3"/>
        <purchase-order-details-table v-if="order" class="mt-5 m-3" :order="order"/>
        <payment-details-page v-if="entry.status!=='Payment Completed'" ref="payment" :payment="payment"
                              :status="entry.status" :entry_id="id" class="m-3"/>
        <payment-details-view v-if="entry.status==='Payment Completed'" :payment="payment"/>
        <attachments :entry_id="id" :status="entry.status" :data="payment"/>
    </div>
</template>

<script>
import PurchaseOrderDetailsTable from './TableComponents/PurchaseOrderDetailsTable';
import PurchaseEntryDetailsTable from './TableComponents/PurchaseEntryDetailsTable';
import PaymentDetailsPage from './TableComponents/PaymentDetails';
import axios from 'secure-axios';
import urls from '../../../../data/urls';
import Attachments from './TableComponents/Attachments';
import PaymentDetailsView from './TableComponents/PaymentDetailsView';

export default {
    name       : 'PurchaseEntryDetailsPage',
    components : {
        PaymentDetailsView,
        Attachments,
        PaymentDetailsPage,
        PurchaseEntryDetailsTable,
        PurchaseOrderDetailsTable
    },
    data () {
        return {
            DataLoading  : false,
            status       : '',
            entry        : {},
            payment      : {},
            order        : {},
            id           : this.$route.params.id,
            statusOption : [
                { label : 'Approve', value : 'Approve' },
                { label : 'Reject', value : 'Reject' }
            ]
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.financeAdmin.purchaseEntry.details, { entry_id : that.id });
            const json = response.data;
            if (json.error === false) {
                that.entry = json.entry;
                that.payment = json.payment;
                that.order = json.order;
                that.DataLoading = false;
            } else {
                that.$notify('Details not found.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.DataLoading = false;
            }
            that.DataLoading = false;
        }
    }
};
</script>

<style scoped>
.label {
    font-weight: 500;
}
</style>
