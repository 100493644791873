<template>
    <div>
        <custom-vue-table :showSearchBox="false" class="mt-5" title="Attachments" :fields="fields" :url="listAttachment" :per-page="10"
                          ref="table" :extra-params="{entry_id}">
            <template #filters>
                <div class="btn-group ml-3">
                    <btn v-if="status!=='Payment Completed'" icon="fa fa-plus" size="md" @click="$refs.addModal.show()" text="Add"/>
                </div>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <btn v-if="status!=='Payment Completed'" class="btn-basic-b" size="xs" @click="setDelete(rowData)" icon="fa fa-trash"></btn>
                    <btn v-if="status!=='Payment Completed'" class="btn-basic-b" size="xs" @click="setEdit(rowData)" icon="fa fa-pencil"></btn>
                    <btn class="btn-basic-b" size="xs" icon="fa fa-eye" @click="viewFile(rowData)"></btn>
                </div>
            </template>

        </custom-vue-table>
        <modal no-close-on-backdrop title="Update Attachments" ref="editModal" width="30r">
            <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}"
                    :initial-data="editingItem" :save-params="{entry_id, purchase_entry : entry_id}">
                <div class="row">
                    <div class="col">
                        <validated-file-input label="Attachment" v-model="model.attachments" :disabled="loading"/>
                    </div>
                </div>
                <btn size="xs" text="Update" :loading="loading" loading-text="Updating..."></btn>
            </b-form>
        </modal>
        <modal no-close-on-backdrop title="Upload Attachments" ref="addModal" width="30r">
            <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" :save-params="{entry_id, purchase_entry : entry_id}">
                <div class="row mb-2">
                    <div class="col">
                        <validated-file-input label="Attachment" v-model="model.attachments" :disabled="loading"/>
                    </div>
                </div>
                <btn size="xs" text="Upload" :loading="loading" loading-text="Uploading..."></btn>
            </b-form>
        </modal>
        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the file. Are you sure
                ?</p>

            <template #loading>
                <delete-animation/>
                Please wait while we delete the file.
            </template>
        </delete-modal>
    </div>
</template>

<script>
import urls from '../../../../../data/urls';

export default {
    name  : 'Attachments',
    props : {
        entry_id : { type : String },
        status   : { type : String }
    },
    data () {
        return {
            loading        : false,
            addUrl         : urls.financeAdmin.purchaseEntry.addAttachment,
            deleteUrl      : urls.financeAdmin.purchaseEntry.deleteAttachment,
            listAttachment : urls.financeAdmin.purchaseEntry.listAttachment,
            deletingItem   : {
                id : ''
            },
            editingItem : {
                id : ''
            },
            fields : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'file_name',
                    sortField : 'file_name',
                    title     : 'File'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        viewFile (item) {
            window.open(item.file);
        },
        setEdit (item) {
            this.editingItem.id = item.id;
            this.$refs.editModal.show();
        },
        setDelete (item) {
            this.deletingItem.id = item.id;
            this.$refs.deleteModal.show();
        },
        deleteComplete () {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$refs.table.refreshTable();
        },
        formSuccess () {
            this.$refs.addModal.close();
            this.$refs.editModal.close();
            this.$refs.table.refreshTable();
        }
    }
};
</script>
